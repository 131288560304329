<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>KAFKA</h3>
    <ul>
      <li><a href="https://www.lidy.synology.me/kafka/swagger-ui/index.html" target="_blank" rel="noopener">KAFKA</a></li>
    </ul>
    <h3>Redis</h3>
    <ul>
      <li><a href="https://www.lidy.synology.me/redis/swagger-ui/index.html" target="_blank" rel="noopener">Redis</a></li>
    </ul>
    <h3>SpringJpa</h3>
    <ul>
      <li><a href="https://www.lidy.synology.me/springjpa/swagger-ui/index.html" target="_blank" rel="noopener">SpringJpa</a></li>
    </ul>
    <h3>PythonFastAPI</h3>
    <ul>
      <li><a href="https://fast.lidy.synology.me/docs" target="_blank" rel="noopener">PythonFastAPI</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
